
function FeaturedCompany() { 
	return( 
	<> 


<section class="about-area bg-color pt-90 pb-90">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-6">
                            <div class="about-img mb-50">
                                <img src="assets/images/1.png" alt="" />
                                <div class="about-img-meta">
                                    <span><i class="fa-solid fa-star"></i>4.9/5</span>
                                    <h5>Clients rate professionals on Pixalive</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-10">
                            <div class="about-content mb-50">
                                <div class="section-area mb-20">
                                    <span class="section-subtitle">Find talent your way</span>
                                    <h2 class="section-title mb-15">Why businesses turn to Upwork</h2>
                                    <p class="section-text">
                                        Through a combination of lectures, readings, discussions, students will gain a solid foundation in educational psychology.
                                    </p>
                                </div>
                                <div class="about-content-list">
                                    <ul>
                                        <li>International course collection in 14 languages</li>
                                        <li>Top certifications in tech and business</li>
                                        <li>Access to 35,000+ top Eduan courses, anytime, anywhere</li>
                                    </ul>
                                </div>
                                <div class="about-content-button">
                                    <a href="about.html" class="theme-btn about-btn theme-btn-medium">More Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="section-title text-center">
                        <h3 class="title">Company of the month</h3>
                        <p class="text-muted">List your Company among the leaders of the industry, promote your work, create original content.</p>
                    </div>
                </div>                
            </div>
            
            <div class="row">
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 item-5">
                    <a href="#">
                        <div class="item-logo">
                            <div class="image-left ">
                                <img alt="" class="rounded-3" src="assets/images/company-02.png"/>
                            </div>
                            <div class="text-info-right">
                                <h4>Pixalive Ventures </h4>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <img alt="" src="assets/images/star.svg"/>
                                <span class="font-xs color-text-mutted ml-10">
                                <span>(</span><span>68</span><span>)</span></span>
                            </div>
                            <div class="text-info-bottom">
                                <span class="font-xs color-text-mutted icon-location">Bangalore</span>
                                <span class="font-xs color-text-mutted float-end">25 <span> Employees</span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            
            </div>
        </div>
        
    </section>

    </> 
  ); 
  } 
  export default FeaturedCompany;