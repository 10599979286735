
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Banner from "./pages/Home/Banner";
import ContactUs from "./pages/ContactUs/ContactUs";
import CompanySearch from "./pages/Home/CompanySearch";
import FeaturedCompany from "./pages/Home/FeaturedCompany";
import CompanyMonth from "./pages/Home/CompanyMonth";
import Subscribe from "./pages/Home/Subscribe";
import Trusted from "./pages/Home/Trusted";
import Testimonial from "./pages/Home/Testimonial";
import Jobs from "./pages/Jobs/Jobs";
import Feed from "./pages/Feed/Feed";
import Company from "./pages/Company/Company";
import Talents from "./pages/Talents/Talents";
import Clients from "./pages/Clients/Clients";
import Login from "./pages/Login/Login";
import Register from "./pages/Login/Register";
import RightSideBar from "./pages/Feed/RightSideBar";
import LeftSideBar from "./pages/Feed/LeftSideBar";


const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Banner' element={<Banner/>} /> 
      <Route path='/CompanySearch' element={<CompanySearch/>} /> 
      <Route path='/FeaturedCompany' element={<FeaturedCompany/>} /> 
      <Route path='/CompanyMonth' element={<CompanyMonth/>} /> 
      <Route path='/ContactUs' element={<ContactUs/>} /> 
      <Route path='/Subscribe' element={<Subscribe/>} /> 
      <Route path='/Trusted' element={<Trusted/>} /> 
      <Route path='/Testimonial' element={<Testimonial/>} /> 
      <Route path='/Jobs' element={<Jobs/>} /> 
      <Route path='/Company' element={<Company/>} /> 
      <Route path='/Feed' element={<Feed/>} /> 
      <Route path='/Clients' element={<Clients/>} /> 
      <Route path='/Talents' element={<Talents/>} /> 
      <Route path='/Login' element={<Login/>} /> 
      <Route path='/Register' element={<Register/>} /> 
      <Route path='/RightSideBar' element={<RightSideBar/>} /> 
      <Route path='/LeftSideBar' element={<LeftSideBar/>} /> 

      
      
    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
