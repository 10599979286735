
function Testimonial() { 
	return( 
	<> 

      <section class="section mt-50 bg-grey-80">
        <div class="container">
          <div class="row align-items-end">
            <div class="col-lg-8 col-md-8">
              <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >What our custommers are saying</h2>
              <p class="font-lg color-gray-500 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >Hear from our users who have saved thousands on their Startup<br class="d-none d-lg-block"/> and SaaS solution spend</p>
            </div>
            <div class="col-lg-4 col-md-4 text-md-end text-start"><a class="btn btn-default font-sm-bold pl-0 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >View All
                <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg></a></div>
          </div>
          <div class="mt-50 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >
            <div class="box-swiper">
              <div class="swiper-container swiper-group-3 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                <div class="swiper-wrapper" id="swiper-wrapper-110f6653e197cd035" aria-live="off" ><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-1.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="2 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-2.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-3.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="4 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-4.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="5 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-5.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide swiper-slide-next" data-swiper-slide-index="2" role="group" aria-label="6 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-6.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="7 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-7.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="8 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-8.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="9 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="../assets/images/user/user-9.jpg" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div></div>
                <div class="swiper-pagination swiper-pagination-2 swiper-pagination-group-3 swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
              <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
          </div>
        </div>
      </section>

    </> 
  ); 
  } 
  export default Testimonial;