
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function ContactUs() { 
	return( 
	<>
     
      <Header />
      <main class="inner-page" id="main-container">
      <section class="section banner-contact">
        <div class="container">
          <div class="banner-1">
            <div class="row align-items-center">
              <div class="col-lg-7"><span class="title-line line-48 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Get in Touch</span>
                <h1 class="color-brand-1 mb-20 mt-10 wow animate__animated animate__fadeIn" data-wow-delay=".2s">We’d love to hear<br class="d-none d-lg-block"/>from you.</h1>
                <div class="row">
                  <div class="col-lg-9">
                    <p class="font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".4s">Request a call, ask a question, or get in with us touch here. If you are interested to know about the PCS contact us here.</p>
                  </div>
                </div>  
              </div>
              {/* <div class="col-lg-5 d-none d-lg-block">
                <div class="box-banner-contact"><img src="assets/images/banner.png" alt="" /></div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section class=" mt-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-delay=".0s">
              <div class="card-small card-small-2">
                <div class="card-image">
                  <div class="box-image"><img src="assets/images/headphone.png" alt="" /></div>
                </div>
                <div class="card-info">
                  <h6 class="color-brand-1 mb-10">Help &amp; support</h6>
                  <p class="font-md color-grey-500">For help with a current product or service or refer to FAQs and developer tools<br/>  <span class="__cf_email__" data-cfemail="f5868085859a8781b594999c819d90989086db969a98">info@pixaliveservices.com</span> </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
              <div class="card-small card-small-2">
                <div class="card-image">
                  <div class="box-image"><img src="assets/images/phone.png" alt="" /></div>
                </div>
                <div class="card-info">
                  <h6 class="color-brand-1 mb-10">Call Us</h6>
                  <p class="font-md color-grey-500">Call us to speak to a member of our team.<br/>+91 87785 84566</p>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-delay=".2s">
              <div class="card-small card-small-2">
                <div class="card-image">
                  <div class="box-image"><img src="assets/images/chart.png" alt="" /></div>
                </div>
                <div class="card-info">
                  <h6 class="color-brand-1 mb-10">Bussiness Department</h6>
                  <p class="font-md color-grey-500">Contact the sales department about cooperation projects<br/>(+01) 789 456 23</p>
                </div>
              </div>
            </div> */}
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-delay=".3s">
              <div class="card-small card-small-2">
                <div class="card-image">
                  <div class="box-image"><img src="assets/images/earth.png" alt="" /></div>
                </div>
                <div class="card-info">
                  <h6 class="color-brand-1 mb-10">Global branch</h6>
                  <p class="font-md color-grey-500"> Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd Phase, Electronic City, Karnataka 560100</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section mt-80 mb-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <h2 class="color-brand-1 mb-15">Get in touch</h2>
              <p class="font-md color-grey-500">Do you want to know more or contact our sales department?</p>
              <div class="mt-50">
                <div class="wow animate__animated animate__fadeInUp" data-wow-delay=".0s">
                  <div class="card-offer card-we-do card-contact hover-up">
                    <div class="card-image"><img src="assets/images/img1.png" alt="" /></div>
                    <div class="card-info">
                      <h6 class="color-brand-1 mb-10">Visit the Knowledge Base</h6>
                      <p class="font-md color-grey-500 mb-5">Browse customer support articles and step-by-step instructions for specific features.</p>
                    </div>
                  </div>
                </div>
                <div class="wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                  <div class="card-offer card-we-do card-contact hover-up">
                    <div class="card-image"><img src="assets/images/img2.png" alt="" /></div>
                    <div class="card-info">
                      <h6 class="color-brand-1 mb-10">Watch Product Videos</h6>
                      <p class="font-md color-grey-500 mb-5">Watch our video tutorials for visual walkthroughs on how to use our features.</p>
                    </div>
                  </div>
                </div>
                <div class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                  <div class="card-offer card-we-do card-contact hover-up">
                    <div class="card-image"><img src="assets/images/img3.png" alt="" /></div>
                    <div class="card-info">
                      <h6 class="color-brand-1 mb-10">Get in touch with Sales</h6>
                      <p class="font-md color-grey-500 mb-5">Let us talk about how we can help your enterprise.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="box-form-contact wow animate__animated animate__fadeIn" data-wow-delay=".6s">
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-user" type="text" placeholder="Your name"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-email" type="text" placeholder="Email"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-phone" type="text" placeholder="Phone"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-company" type="text" placeholder="Company"/>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mb-25">
                      <input class="form-control" type="text" placeholder="Subject"/>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mb-25">
                      <textarea class="form-control textarea-control" placeholder="Write something"></textarea>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-3 col-md-5 col-sm-6 col-9">
                    <div class="form-group">
                      <button class="btn btn-brand-1 hover-up" type="submit">Send message
                       
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="google_map" class="google_map">
                <div class="map_container">
                    <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15559.671290268201!2d77.6706383!3d12.8485841!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d95c77b9285%3A0x580316a77880fa5!2sPixalive!5e0!3m2!1sen!2sin!4v1704900918681!5m2!1sen!2sin" height="500" width="100%"></iframe>
                    </div>
                </div>
            </div>
                
        <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default ContactUs;