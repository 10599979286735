
function Banner() { 
	return( 
	<>

<section class="bg-home banner-hero" id="home"> 
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="text-center text-white mb-5">
                            <h1 class="display-5 fw-semibold mb-3">Unlocking IT Excellence with more than <span class="text-warning fw-bold">10,000+ </span>
                            companies, One Platform, Many Solutions.</h1>
                            <p class="fs-17">Explore the world’s leading local and global Company to find the one that suits your needs.</p>
                        </div>
                    </div>
                    
                </div> 
                
            
                <div class="row">
                    <div class="col-lg-8 mx-auto">
                    <form action="#">
                    <div class="registration-form">
                        <div class="row g-0">
                            <div class="col-lg-3">
                                <div class="filter-search-form filter-border mt-3 mt-lg-0">
                                    <i class="uil uil-map-marker"></i>
                                    <select class="form-select form-control" data-trigger name="choices-single-location" id="choices-single-location" aria-label="Default select example">
                                        <option value="" disabled="" hidden="">State</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="West Bengal">West Bengal</option>
                                        <option value="Andaman &amp; Nicobar Islands">Andaman &amp; Nicobar Islands</option>
                                        <option value="Dadra and Nagar Haveli and">Dadra and Nagar Haveli and</option>
                                        <option value="Daman &amp; Diu">Daman &amp; Diu</option>
                                        <option value="Jammu &amp; Kashmir">Jammu &amp; Kashmir</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Ladakh">Ladakh</option>
                                        <option value="Puducherry">Puducherry</option>
                                    </select> 
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="filter-search-form filter-border mt-3 mt-lg-0">
                                    <i class="uil uil-map-marker"></i>
                                    <select class="form-select" data-trigger name="choices-single-location" id="choices-single-location" aria-label="Default select example">
                                        <option value="" disabled="" hidden="">City</option>
                                        <option value="Agra">Agra</option>
                                        <option value="Ahmedabad">Ahmedabad</option>
                                        <option value="Ajmer">Ajmer</option>
                                        <option value="Amritsar">Amritsar</option>
                                        <option value="Bangalore">Bangalore</option>
                                        <option value="Banswara">Banswara</option>
                                        <option value="Bhadreswar">Bhadreswar</option>
                                        <option value="Bhatinda">Bhatinda</option>
                                        <option value="Bhopal">Bhopal</option>
                                        <option value="Bhubaneswar">Bhubaneswar</option>
                                        <option value="Bikaner">Bikaner</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chennai">Chennai</option>
                                        <option value="Chilakaluripet">Chilakaluripet</option>
                                        <option value="Cuttack">Cuttack</option>
                                        <option value="Datia">Datia</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Etawah">Etawah</option>
                                        <option value="Faridabad">Faridabad</option>
                                        <option value="Gandhinagar">Gandhinagar</option>
                                        <option value="Gangtok">Gangtok</option>
                                        <option value="Ghaziabad">Ghaziabad</option>
                                        <option value="Hajipur">Hajipur</option>
                                        <option value="Hosur">Hosur</option>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Jaipur">Jaipur</option>
                                        <option value="Jamshedpur">Jamshedpur</option>
                                        <option value="Jhansi">Jhansi</option>
                                        <option value="Junagadh">Junagadh</option>
                                        <option value="Kalyani">Kalyani</option>
                                        <option value="Kanpur">Kanpur</option>
                                        <option value="Kapurthala">Kapurthala</option>
                                        <option value="Kasganj">Kasganj</option>
                                        <option value="Kochi">Kochi</option>
                                        <option value="Kolkata">Kolkata</option>
                                        <option value="Lucknow">Lucknow</option>
                                        <option value="Madurai">Madurai</option>
                                        <option value="Mathura">Mathura</option>
                                        <option value="Meerut">Meerut</option>
                                        <option value="Mumbai">Mumbai</option>
                                        <option value="Mysuru">Mysuru</option>
                                        <option value="Nagda">Nagda</option>
                                        <option value="Nagpur">Nagpur</option>
                                        <option value="Nashik">Nashik</option>
                                        <option value="Patna">Patna</option>
                                        <option value="Pune">Pune</option>
                                        <option value="Raipur">Raipur</option>
                                        <option value="Rajamundry">Rajamundry</option>
                                        <option value="Rohtak">Rohtak</option>
                                        <option value="Roorkee">Roorkee</option>
                                        <option value="Salem">Salem</option>
                                        <option value="Srinagar">Srinagar</option>
                                        <option value="Sujangarh">Sujangarh</option>
                                        <option value="Surat">Surat</option>
                                        <option value="Udaipur">Udaipur</option>
                                        <option value="Vijaywada">Vijaywada</option>
                                        <option value="Visakhapatnam">Visakhapatnam</option>
                                        </select>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="filter-search-form mt-3 mt-lg-0">
                                    <i class="uil uil-clipboard-notes"></i>
                                    <select class="form-select" data-trigger name="choices-single-categories" id="choices-single-categories" aria-label="Default select example">
                                        <option value="4">Service</option>
                                        <option value="1">IT & Software</option>
                                        <option value="3">Software Service</option>
                                        <option value="5">Marketing Service</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mt-3 mt-lg-0 h-100">
                                    <button class="btn submit-btn w-100 h-100" type="submit"><i class="uil uil-search me-1"></i> Find</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                        <ul class="treding-keywords list-inline mb-0 text-white-50 mt-4 mt-lg-3 text-center">
                            <li class="list-inline-item text-white"><i class="mdi mdi-tag-multiple-outline text-warning fs-18"></i> Trending Keywords:</li>
                            <li class="list-inline-item"><a href="javascript:void(0)">Bengaluru,</a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)">Software Service,</a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)">Manager</a></li> 
                        </ul>
                    </div>
                    
                </div>
                
            </div>
            
        </section> 

      <section>
        <div class="box-radius-bottom">
          <div class="container">
            <div class="text-center">
              <h3 class="color-brand-1 mb-15 wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" >Trusted by Top Category Leaders <span>1000+</span></h3>
              <p class="font-lg color-grey-500 wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" >We helped these brands excel in the IT services & projects.<br class="d-none d-lg-block"/>Join them. Share or take the projects.</p>
            </div>
            <div class="mt-30 wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" >
              <div class="box-swiper">
                <div class="swiper-container swiper-group-8 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                  <div class="swiper-wrapper" id="swiper-wrapper-10dc903610c2bb9ee8" aria-live="off" ><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0" role="group" aria-label="1 / 24" ><img src="assets/images/placed.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1" role="group" aria-label="2 / 24" ><img src="assets/images/cuebiq.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" role="group" aria-label="3 / 24" ><img src="assets/images/factual.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" role="group" aria-label="4 / 24" ><img src="assets/images/placeiq.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" role="group" aria-label="5 / 24" ><img src="assets/images/airmeet.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="5" role="group" aria-label="6 / 24" ><img src="assets/images/spen.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="6" role="group" aria-label="7 / 24" ><img src="assets/images/klippa.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="7" role="group" aria-label="8 / 24" ><img src="assets/images/matrix.png" alt="" /></div>
                    <div class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" role="group" aria-label="9 / 24" ><img src="assets/images/h_1.png" alt="" /></div>
                    <div class="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" role="group" aria-label="10 / 24" ><img src="assets/images/h_2.png" alt="" /></div>
                    <div class="swiper-slide" data-swiper-slide-index="2" role="group" aria-label="11 / 24" ><img src="assets/images/h_3.png" alt="" /></div>
                    <div class="swiper-slide" data-swiper-slide-index="3" role="group" aria-label="12 / 24" ><img src="assets/images/h_4.png" alt="" /></div>
                    <div class="swiper-slide" data-swiper-slide-index="4" role="group" aria-label="13 / 24" ><img src="assets/images/h_5.png" alt="" /></div>
                    <div class="swiper-slide" data-swiper-slide-index="5" role="group" aria-label="14 / 24" ><img src="assets/images/h_6.png" alt="" /></div>
                    <div class="swiper-slide" data-swiper-slide-index="6" role="group" aria-label="15 / 24" ><img src="assets/images/h_1.png" alt="" /></div>
                    <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="7" role="group" aria-label="16 / 24" ><img src="assets/images/h_2.png" alt="" /></div>
                  <div class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" role="group" aria-label="17 / 24" ><img src="assets/images/placed.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" role="group" aria-label="18 / 24" ><img src="assets/images/cuebiq.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" role="group" aria-label="19 / 24" ><img src="assets/images/factual.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" role="group" aria-label="20 / 24" ><img src="assets/images/placeiq.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" role="group" aria-label="21 / 24" ><img src="assets/images/airmeet.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="5" role="group" aria-label="22 / 24" ><img src="assets/images/spen.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="6" role="group" aria-label="23 / 24" ><img src="assets/images/klippa.png" alt="" /></div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="7" role="group" aria-label="24 / 24" ><img src="assets/images/matrix.png" alt="" /></div></div>
                  <div class="swiper-pagination swiper-pagination-group-8 swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
              </div>
            </div>
          </div>
        </div>
      </section>

 

  </> 
  ); 
  } 
  export default Banner;