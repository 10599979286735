
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function Jobs() { 
	return( 
	<>
     
      <Header />
      <main class="inner-page" id="main-container">
      <section class="section banner-contact">
        <div class="container">
          <div class="banner-1">
            <div class="row align-items-center">
              <div class="col-lg-7"><span class="title-line line-48 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Job Vacancies</span>
                <h1 class="color-brand-1 mb-20 mt-10 wow animate__animated animate__fadeIn" data-wow-delay=".2s">522 Jobs Available Now</h1>
                <div class="row">
                  <div class="col-lg-9">
                    <p class="font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".4s">Apply for these jobs to advance your career, analyse, and switch wisely. Wishing you the best for your success.</p>
                  </div>
                </div>  
              </div>
              {/* <div class="col-lg-5 d-none d-lg-block">
                <div class="box-banner-contact"><img src="assets/images/banner.png" alt="" /></div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section class="section-box mt-30">
  <div class="container">
    <div class="row flex-row-reverse">
      <div class="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
        <div class="content-page">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-1.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">LinkedIn</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">UI / UX Designer fulltime</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Adobe XD</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Figma</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Photoshop</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-2.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Adobe Ilustrator</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Full Stack Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Part time</span>
                    <span class="card-time">5 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">React</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">NodeJS</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-3.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Bing Search</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Java Software Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Python</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">AWS</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Photoshop</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-4.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Dailymotion</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Frontend Developer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Typescript</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Java</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-5.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Linkedin</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">React Native Web Developer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Angular</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">2-5 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-6.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Quora JSC</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Senior System Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Part time</span>
                    <span class="card-time">5 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/job-details">PHP</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Android</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-7.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Nintendo</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Products Manager</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/job-details">ASP .Net</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Figma</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-8.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Periscope</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Lead Quality Control QA</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/job-details">iOS</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Laravel</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Golang</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-1.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">LinkedIn</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">UI / UX Designer fulltime</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Adobe XD</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Figma</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Photoshop</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">2-5 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-2.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Adobe Ilustrator</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Full Stack Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Part time</span>
                    <span class="card-time">5 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">React</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">NodeJS</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-3.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Bing Search</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Java Software Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Python</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">AWS</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Photoshop</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-4.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Dailymotion</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Frontend Developer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Typescript</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Java</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-5.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Linkedin</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">React Native Web Developer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Angular</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">2-5 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-6.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Quora JSC</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Senior System Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Part time</span>
                    <span class="card-time">5 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/job-details">PHP</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Android</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-7.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Nintendo</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Products Manager</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/job-details">ASP .Net</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Figma</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-8.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Periscope</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Lead Quality Control QA</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Full time</span>
                    <span class="card-time">6 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/job-details">iOS</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Laravel</a>
                    <a class="btn btn-grey-small mr-5" href="/job-details">Golang</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-1.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">LinkedIn</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">UI / UX Designer fulltime</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Adobe XD</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Figma</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Photoshop</a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">2-5 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-2 hover-up">
                <div class="card-grid-2-image-left">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-2.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">Adobe Ilustrator</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info">
                  <h6>
                    <a href="/job-details">Full Stack Engineer</a>
                  </h6>
                  <div class="mt-5">
                    <span class="card-briefcase">Part time</span>
                    <span class="card-time">5 <span> minutes ago</span>
                    </span>
                  </div>
                  <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                  <div class="mt-30">
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">React</a>
                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">NodeJS </a>
                  </div>
                  <div class="card-2-bottom mt-30">
                    <div class="row">
                      <div class="col-lg-7 col-7">
                        <span class="card-text-price">3-6 Lakhs</span>
                        
                      </div>
                      <div class="col-lg-5 col-5 text-end">
                        <div class="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="paginations">
          <ul class="pager">
            <li>
              <a class="pager-prev" href="#"></a>
            </li>
            <li>
              <a class="pager-number" href="/jobs-grid#">1</a>
            </li>
            <li>
              <a class="pager-number" href="/jobs-grid#">2</a>
            </li>
            <li>
              <a class="pager-number" href="/jobs-grid#">3</a>
            </li>
            <li>
              <a class="pager-number" href="/jobs-grid#">4</a>
            </li>
            <li>
              <a class="pager-number" href="/jobs-grid#">5</a>
            </li>
            <li>
              <a class="pager-number active" href="/jobs-grid#">6</a>
            </li>
            <li>
              <a class="pager-number" href="/jobs-grid#">7</a>
            </li>
            <li>
              <a class="pager-next" href="#"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12 col-12">
        <div class="sidebar-shadow none-shadow mb-30">
          <div class="sidebar-filters"> 
            <div class="filter-block mb-20">
              <h5 class="medium-heading mb-15">Industry</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">All</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">180</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Software</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">12</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Finance</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">23</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Recruting</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">43</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Management</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">65</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Advertising</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">76</span>
                  </li>
                </ul>
              </div>
            </div>
            
            <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Popular Keyword</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Software</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">24</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Developer</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">45</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Web</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">57</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Position</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Senior</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">12</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Junior</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">35</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Fresher</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">56</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Experience Level</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Internship</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">56</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Entry Level</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">87</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Associate</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">24</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Mid Level</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">45</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Director</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">76</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Executive</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">89</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Onsite/Remote</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">On-site</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">12</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Remote</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">65</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Hybrid</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">58</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Job Posted</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">All</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">78</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">1 day</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">65</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">7 days</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">24</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">30 days</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">56</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="filter-block mb-20">
              <h5 class="medium-heading mb-15">Job type</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Full Time</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">25</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Part Time</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">64</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Remote Jobs</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">78</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Freelancer</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">97</span>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      
                
      <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default Jobs;