import React from 'react';

function  Header () {
  return (
  <>
  


  <div class="box-notify bg-brand-1">
      <div class="container position-relative">
        <div class="box-container-sw">
          <div class="box-button-slider">
            <div class="swiper-button-prev swiper-button-prev-notify" tabindex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-d354219a61a60c1d">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
              </svg>
            </div>
            <div class="swiper-button-next swiper-button-next-notify" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-d354219a61a60c1d">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
              </svg>
            </div>
          </div>
          <div class="box-swiper">
            <div class="swiper-container swiper-notify swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
              <div class="swiper-wrapper" id="swiper-wrapper-d354219a61a60c1d" aria-live="off" ><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="1 / 5" ><span class="d-inline-block font-sm color-brand-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.!</span></div>
                <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="2 / 5" ><span class="d-inline-block font-sm color-brand-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></div>
                <div class="swiper-slide swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="3 / 5" ><span class="d-inline-block font-sm color-brand-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></div>
                <div class="swiper-slide swiper-slide-next" data-swiper-slide-index="2" role="group" aria-label="4 / 5" ><span class="d-inline-block font-sm color-brand-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></div>
              <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="5 / 5" ><span class="d-inline-block font-sm color-brand-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></div></div>
            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
          </div>
        </div>
      </div><a class="btn btn-close">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg></a>
    </div>
    <header class="header sticky-bar stick">
      <div class="container">
        <div class="main-header">
          <div class="header-left">
            <div class="header-logo"><a class="d-flex" href="/">
              <img alt="Ecom" src="assets/images/logo-dark.svg" /></a>
            </div>
            <div class="header-nav">
              <nav class="nav-main-menu d-none d-xl-block">
                <ul class="main-menu">
                  <li>
                      <a href="/">Home</a>
                  </li>
                  <li>
                      <a href="/Feed">Feed</a>
                  </li>
                  <li>
                      <a href="/Company">Company</a>
                  </li>
                  <li>
                      <a href="/Clients">Clients</a>
                  </li>
                  <li>
                      <a href="/Talents">Talents</a>
                  </li>
                  <li>
                      <a href="/Jobs">Jobs</a>
                  </li>
                  <li>
                      <a href="/ContactUs">Contact</a>
                  </li>                  
                  <a href="/Login" class="btn btn-login hover-up">Login</a>
                </ul>
              </nav>
              <div class="burger-icon burger-icon-white"><span class="burger-icon-top"></span><span class="burger-icon-mid"></span><span class="burger-icon-bottom"></span></div>
            </div> 
          </div>
        </div>
      </div>
    </header>

    <div class="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
      <div class="mobile-header-wrapper-inner ps ps--active-y">
        <div class="mobile-header-content-area">
          <div class="mobile-logo"><a class="d-flex" href="/">
            <img alt="IORI" src="assets/images/log-dark.svg"/></a></div>
          <div class="burger-icon"><span class="burger-icon-top"></span><span class="burger-icon-mid"></span><span class="burger-icon-bottom"></span></div>
          <div class="perfect-scroll">
            <div class="mobile-menu-wrap mobile-header-border">
              <ul class="nav nav-tabs nav-tabs-mobile mt-25" role="tablist">
                <li><a class="active" href="index.html#tab-menu" data-bs-toggle="tab" role="tab" aria-controls="tab-menu" aria-selected="true">
                    <svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>Menu</a></li>
                <li><a href="index.html#tab-account" data-bs-toggle="tab" role="tab" aria-controls="tab-account" aria-selected="false">
                    <svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>Account</a></li>
                <li><a href="index.html#tab-notification" data-bs-toggle="tab" role="tab" aria-controls="tab-notification" aria-selected="false">
                    <svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                    </svg>Notification</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade active show" id="tab-menu" role="tabpanel" aria-labelledby="tab-menu">
                  <nav class="mt-15">
                    <ul class="mobile-menu font-heading">
                      <li class="has-children"><span class="menu-expand"><svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></span><a class="active" href="index.html">Home</a>
                        <ul class="sub-menu">
                          <li><a href="index.html">Homepage - 1</a></li>
                          <li><a href="index-2.html">Homepage - 2</a></li>
                          <li><a href="index-3.html">Homepage - 3</a></li>
                          <li><a href="index-4.html">Homepage - 4</a></li>
                          <li><a href="index-5.html">Homepage - 5</a></li>
                          <li><a href="index-6.html">Homepage - 6</a></li>
                          <li><a href="index-7.html">Homepage - 7</a></li>
                          <li><a href="index-8.html">Homepage - 8</a></li>
                          <li><a href="index-9.html">Homepage - 9</a></li>
                          <li><a href="index-10.html">Homepage - 10</a></li>
                          <li><a href="index-11.html">Homepage - 11</a></li>
                          <li><a href="index-12.html">Homepage - 12</a></li>
                        </ul>
                      </li>
                      <li class="has-children"><span class="menu-expand"><svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></span><a href="index.html#">Company</a>
                        <ul class="sub-menu">
                          <li><a href="about.html">About Us</a></li>
                          <li><a href="service.html">Our Services</a></li>
                          <li><a href="pricing.html">Pricing Plan</a></li>
                          <li><a href="team.html">Meet Our Team</a></li>
                          <li><a href="help.html">Help Center</a></li>
                          <li><a href="term-conditions.html">Term and Conditions</a></li>
                        </ul>
                      </li>
                      <li class="has-children"><span class="menu-expand"><svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></span><a href="index.html#">Career</a>
                        <ul class="sub-menu">
                          <li><a href="career.html">Jobs Listing</a></li>
                          <li><a href="job-detail.html">Job Details</a></li>
                        </ul>
                      </li>
                      <li class="has-children"><span class="menu-expand"><svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></span><a href="blog.html">Blog</a>
                        <ul class="sub-menu">
                          <li><a href="blog.html">Blog Listing 1</a></li>
                          <li><a href="blog-2.html">Blog Listing 2</a></li>
                          <li><a href="blog-detail.html">Blog Details</a></li>
                        </ul>
                      </li>
                      <li class="has-children"><span class="menu-expand"><svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></span><a href="index.html#">Shop</a>
                        <ul class="sub-menu">
                          <li><a href="shop-grid.html">Products Listing 1</a></li>
                          <li><a href="shop-list.html">Products Listing 2</a></li>
                          <li><a href="product-detail.html">Product Details</a></li>
                        </ul>
                      </li>
                      <li class="has-children"><span class="menu-expand"><svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></span><a href="index.html#">Pages</a>
                        <ul class="sub-menu">
                          <li><a href="register.html">Register</a></li>
                          <li><a href="login.html">Login</a></li>
                          <li><a href="coming-soon.html">Coming soon</a></li>
                          <li><a href="404.html">Error 404</a></li>
                        </ul>
                      </li>
                      <li><a href="contact.html">Contact</a></li>
                    </ul>
                  </nav>
                </div>
                <div class="tab-pane fade" id="tab-account" role="tabpanel" aria-labelledby="tab-account">
                  <nav class="mt-15">
                    <ul class="mobile-menu font-heading">
                      <li><a class="active" href="login.html">My Profile</a></li>
                      <li><a href="index.html#">Work Preferences</a></li>
                      <li><a href="index.html#">My Boosted Shots</a></li>
                      <li><a href="index.html#">My Collections</a></li>
                      <li><a href="index.html#">Account Settings</a></li>
                      <li><a href="index.html#">Go Pro</a></li>
                      <li><a href="register.html">Sign Out</a></li>
                    </ul>
                  </nav>
                </div>
                <div class="tab-pane fade" id="tab-notification" role="tabpanel" aria-labelledby="tab-notification">
                  <p class="font-sm-bold color-brand-1 mt-30">Today</p>
                  <div class="notifications-item">
                    <div class="item-notify">
                      <div class="item-image"><img src="images/user1.png" alt="" /></div>
                      <div class="item-info">
                        <p class="color-grey-500 font-xs"><strong class="font-xs-bold">Steven Job</strong>like started a poll in your post “How to be a good trader in 2023”</p>
                      </div>
                      <div class="item-time"><span class="color-grey-500 font-xs">Just now</span></div>
                    </div>
                    <div class="item-notify">
                      <div class="item-image"><img src="images/user2.png" alt="" /></div>
                      <div class="item-info">
                        <p class="color-grey-500 font-xs"><strong class="font-xs-bold">Steven Job</strong>like started a poll in your post “How to be a good trader in 2023”</p>
                      </div>
                      <div class="item-time"><span class="color-grey-500 font-xs">Just now</span></div>
                    </div>
                  </div>
                  <p class="font-sm-bold color-brand-1 mt-30">Yesterday</p>
                  <div class="notifications-item">
                    <div class="item-notify">
                      <div class="item-image"><img src="images/user3.png" alt="" /></div>
                      <div class="item-info">
                        <p class="color-grey-500 font-xs"><strong class="font-xs-bold">Steven Job</strong>like started a poll in your post “How to be a good trader in 2023”</p>
                      </div>
                      <div class="item-time"><span class="color-grey-500 font-xs">Just now</span></div>
                    </div>
                    <div class="item-notify">
                      <div class="item-image"><img src="images/user4.png" alt="" /></div>
                      <div class="item-info">
                        <p class="color-grey-500 font-xs"><strong class="font-xs-bold">Steven Job</strong>like started a poll in your post “How to be a good trader in 2023”</p>
                      </div>
                      <div class="item-time"><span class="color-grey-500 font-xs">Just now</span></div>
                    </div>
                    <div class="item-notify">
                      <div class="item-image"><img src="images/user5.png" alt="" /></div>
                      <div class="item-info">
                        <p class="color-grey-500 font-xs"><strong class="font-xs-bold">Steven Job</strong>like started a poll in your post “How to be a good trader in 2023”</p>
                      </div>
                      <div class="item-time"><span class="color-grey-500 font-xs">Just now</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="site-copyright color-grey-400 mt-0">
              <div class="box-download-app">
                <p class="font-xs color-grey-400 mb-25">Download our Apps and get extra 15% Discount on your first Order…!</p>
                <div class="mb-25"><a class="mr-10" href="index.html#"><img src="images/appstore.png" alt="" /></a><a href="index.html#"><img src="images/google-play.png" alt="" /></a></div>
                <p class="font-sm color-grey-400 mt-20 mb-10">Secured Payment Gateways</p><img src="images/payment-method.png" alt="" />
              </div>
              <div class="mb-0">Copyright 2023 © IORI - Marketplace Template.<br/>Designed by<a href="http://alithemes.com/" target="_blank">&nbsp; AliThemes</a></div>
            </div>
          </div>
        </div>
      <div class="ps__rail-x" ><div class="ps__thumb-x" tabindex="0" ></div></div>
      <div class="ps__rail-y" ><div class="ps__thumb-y" tabindex="0" ></div></div></div>
    </div>
  </>
);
}

export default Header;