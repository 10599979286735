
function RightSideBar() { 
	return( 
	<>

      <div class="col-lg-12 col-md-12 ">
         <aside class="widget-area data-simplebar">
            <div class="widget widget-watch-video">
               <div class="widget-heading">
                  <h3 class="widget-title">Advertisement</h3>
               </div>
               <div class="advertisement-slides owl-carousel owl-theme">
                  <div class="video-item">
                     <img src="../assets/images/explore-events/explore-1.jpg" alt="image" />
                  </div>
               </div>
            </div>
            <div class="widget widget-who-following">
               <div class="widget-heading">
                  <h3 class="widget-title">Trending Company</h3>
                  <span><a href="/SuggestionPeople">See All</a></span>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-2.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Carols Daughter</a></span>
                  <div class="add-friend"><a href="#">Profile</a></div>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-3.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Amazon</a></span>                  
                  <div class="add-friend"><a href="#">Profile</a></div>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-8.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Baseball Savings</a></span>
                  <div class="add-friend"><a href="#">Profile</a></div>
                  {/* <span class="add-web"><a href="#"><i class="material-icons-outlined">public</i></a></span>
                  <span class="add-friend"><a href="#"><i class="material-icons">contact_phone</i></a></span> */}
               </div>
            </div>
         </aside>
      </div>
      <div class="col-lg-12 col-sm-12 cb-dn-none cb-sidebar-sticky">
         <div class="cb-sidebar-card">
            <div class="widget-heading">
               <h3 class="widget-title">Featured Company</h3>
               <span><a href="/SuggestionPeople">See All</a></span>
            </div>
            <div class="cb-sidebar-gallery-head">
               <div class="cb-store-color-filter">
                  <a href="#"><img src="../assets/images/brand-1.png" alt=""/></a>
                  <a href="#"><img src="../assets/images/brand-2.png" alt=""/></a>
                  <a href="#"><img src="../assets/images/brand-3.png" alt=""/></a>
                  <a href="#"><img src="../assets/images/brand-4.png" alt=""/></a>
                  <a href="#"><img src="../assets/images/brand-5.png" alt=""/></a>
                  <a href="#"><img src="../assets/images/brand-6.png" alt=""/></a>
               </div>
            </div>    
         </div>
      </div> 
       
  </> 
  ); 
  } 
  export default RightSideBar;