
function Trusted() { 
	return( 
	<>
    
    <section class="section mt-50">
        <div class="container">
          <div class="row align-items-end">
            <div class="col-lg-8 col-md-8">
              <h2 class="color-brand-1 mb-20 wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" >Join 1000+ Leading Brands: </h2>
              <p class="font-lg color-gray-500 wow animate__ animate__fadeInUp animated" data-wow-delay=".02s" >Discover why Pixalive Services is the #1 Business Consultant</p>
            </div>
            <div class="col-lg-4 col-md-4 text-md-end text-start"><a class="btn btn-default font-sm-bold pl-0 wow animate__ animate__fadeInUp animated" data-wow-delay=".04s" >Learn More
                <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg></a></div>
          </div>
          <div class="row mt-50">
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" >
              <div class="card-offer hover-up">
                <div class="card-image"><img src="assets/images/Isolation_Mode-11.svg" alt="" /></div>
                <div class="card-info">
                  <h4 class="color-brand-1 mb-5">Cloud & Mobile Ready </h4>
                  <p class="font-md color-grey-500 mb-5">Award winning mobile app that’s used by 5 lakh+ users in India</p>
                  <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More
                      <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg></a></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__ animate__fadeInUp animated" data-wow-delay=".1s" >
              <div class="card-offer hover-up">
                <div class="card-image"><img src="assets/images/Isolation_Mode-12.svg" alt="" /></div>
                <div class="card-info">
                  <h4 class="color-brand-1 mb-5">Secure & Reliable</h4>
                  <p class="font-md color-grey-500 mb-5">Powered with Microsoft Azure, GDPR, ISO27001 Compliant: Superior data security</p>
                  <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More
                      <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg></a></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__ animate__fadeInUp animated" data-wow-delay=".2s">
              <div class="card-offer hover-up">
                <div class="card-image"><img src="assets/images/Isolation_Mode-13.svg" alt="" /></div>
                <div class="card-info">
                  <h4 class="color-brand-1 mb-5">Simple & Intuitive</h4>
                  <p class="font-md color-grey-500 mb-5">Powered by InboxforHRTM , a simple interface you don’t need a trainer for.</p>
                  <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More
                      <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg></a></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >
              <div class="card-offer hover-up">
                <div class="card-image"><img src="assets/images/Isolation_Mode-14.svg" alt="" /></div>
                <div class="card-info">
                  <h4 class="color-brand-1 mb-5">Comprehensive & Configurable</h4>
                  <p class="font-md color-grey-500 mb-5">The complete hire-to-retire suite for your people function </p>
                  <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More
                      <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg></a></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".1s" >
              <div class="card-offer hover-up">
                <div class="card-image"><img src="assets/images/Isolation_Mode-15.svg" alt="" /></div>
                <div class="card-info">
                  <h4 class="color-brand-1 mb-5">Easy To Integrate </h4>
                  <p class="font-md color-grey-500 mb-5">Connect with any software using open APIs Easy To Integrate</p>
                  <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More
                      <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg></a></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s" >
              <div class="card-offer hover-up">
                <div class="card-image"><img src="assets/images/Isolation_Mode-11.svg" alt="" /></div>
                <div class="card-info">
                  <h4 class="color-brand-1 mb-5">Value For Money</h4>
                  <p class="font-md color-grey-500 mb-5">Priced competitively with no hidden features costs value for money </p>
                  <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More
                      <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

  </> 
  ); 
  } 
  export default Trusted;