
function HappyClients() { 
	return( 
	<>

          <section class="section pt-90 banner-about">
            <div class="container text-center">
                  <h6 class="color-grey-400 mb-15">Who we are</h6>
                  <h2 class="color-brand-1 mb-15">We are a digital agency that tackles<br class="d-none d-lg-block"/>all your online challenges.</h2>
                  <p class="font-md color-grey-400 mb-30">Social media networks are open to all. Social media is typically used for social interation and<br class="d-none d-lg-block"/>access to news and information, and decison making.</p>
              <div class="box-radius-border mt-50">
                <div class="box-list-numbers">
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-11.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">469</span><span>K+</span></h2>
                    <p class="color-brand-1 font-lg">Social followers</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-12.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">25</span><span>k+</span></h2>
                    <p class="color-brand-1 font-lg">Happy Clients</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-13.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">756</span><span>+</span></h2>
                    <p class="color-brand-1 font-lg">Project Done</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-14.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">100</span><span>+</span></h2>
                    <p class="color-brand-1 font-lg">Global branch</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-15.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">240</span><span>+</span></h2>
                    <p class="color-brand-1 font-lg">Conference</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
       
  </> 
  ); 
  } 
  export default HappyClients;