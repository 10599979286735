
function CompanySearch() { 
	return( 
	<> 
    
    <section class="section bg-grey-80 mt-100">
          <div class="container">
            <div class="row align-items-center mt-50">
              <div class="col-lg-4 col-md-12 col-sm-12 mb-30">
                <span class="title-line line-48 wow animate__ animate__fadeIn animated" data-wow-delay=".0s" >How it work</span>
                <h2 class="color-brand-1 mt-10 mb-20 wow animate__ animate__fadeIn animated" data-wow-delay=".2s" >Grow your online business now</h2>
                <p class="color-grey-500 font-sm wow animate__ animate__fadeIn animated" data-wow-delay=".4s" >We excel in offering a comprehensive range of IT services, digital services, consulting, business solutions, project management, and talent outsourcing all within a single platform, making us a leader in the Indian market.</p>
                <div class="mt-45 wow animate__ animate__fadeIn animated" data-wow-delay=".6s">
                    <a class="btn btn-brand-1 hover-up" href="#">Download App</a>
                    <a class="btn btn-default font-sm-bold hover-up" href="#">Learn More
                    <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg></a>
                </div>
              </div>
              <div class="col-lg-8">
              <div class="box-swiper top-50">
              <div class="swiper-container swiper-group-2">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="card-offer-style-3">
                      <div class="card-head">
                        <div class="card-image"><img src="assets/images/brand-2.png" alt="" /></div>
                        <div class="carrd-title text-info-right">
                          <h4 class="color-brand-1">Pixalive Platform</h4>
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                        </div>
                      </div>
                      <div class="card-info">
                        <p class="font-md color-grey-500">Discover powerful features to boost your productivit. You are always welcome to visit our little den. Professional in teir craft! All products were super amazing with strong attension to details, comps and overall vibe.</p>
                        </div>
                        <div class="text-info-bottom">
                          <div class="icon-location">Bangalore</div>
                          <div class="icon-location1">25 Employees</div>
                        </div>
                        <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More<svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a></div>
                      
                    </div>
                  </div>  
                  <div class="swiper-slide head-bg-5">
                    <div class="card-offer-style-3">
                      <div class="card-head">
                        <div class="card-image"><img src="assets/images/brand-8.png" alt="" /></div>
                        <div class="carrd-title">
                          <h4 class="color-brand-1">Pixalive Service</h4>                          
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                        </div>
                      </div>
                      
                      <div class="card-info">
                        <p class="font-md color-grey-500">Discover powerful features to boost your productivit. You are always welcome to visit our little den. Professional in teir craft! All products were super amazing with strong attension to details, comps and overall vibe.</p>
                        </div>
                      <div class="text-info-bottom">
                            <div class="icon-location">Bangalore</div>
                            <div class="icon-location1">25 Employees</div>
                        </div>
                        <div class="box-button-offer"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More<svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a></div>
                      
                    </div>
                  </div>
                </div>
                <div class="box-button-slider-bottom">
                  <div class="swiper-button-prev swiper-button-prev-group-4" tabindex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-0c1fd48916d20ee10">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                    </svg>
                  </div>
                  <div class="swiper-button-next swiper-button-next-group-4" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-0c1fd48916d20ee10">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
        
          </div>
        </section>

        
        <section class="section mt-50 pt-50 pb-40">
        <div class="container">
          <div class="box-cover-border">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="img-reveal"><img class="d-block" src="assets/images/img-marketing.png" alt=""/></div>
              </div>
              <div class="col-lg-6">
                <div class="box-info-video"><span class="btn btn-tag wow animate__animated animate__fadeInUp" data-wow-delay=".0s">Get in touch</span>
                  <h2 class="color-brand-1 mt-15 mb-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Want to talk to a marketing expert?</h2>
                  <p class="font-md color-grey-500 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum — semper quis lectus nulla. Interactively transform magnetic growth strategies whereas prospective "outside the box" thinking.</p>
                  <div class="box-button text-start mt-25 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a class="btn btn-brand-1 hover-up" href="#">Contact Us</a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </> 
  ); 
  } 
  export default CompanySearch;