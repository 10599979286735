
function CompanyMonth() { 
	return( 
	<> 

<section class="section mt-50  bg-grey-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeIn" data-wow-delay=".0s">What We Offer</h2>
              <p class="font-lg color-gray-500 wow animate__animated animate__fadeIn" data-wow-delay=".2s">What makes us different from others? We give holistic solutions<br class="d-none d-lg-block"/>with strategy, design & technology.</p>
            </div>
          </div>
          <div class="mt-50 wow animate__animated animate__fadeIn" data-wow-delay=".0s">
            <div class="box-swiper">
              <div class="swiper-container swiper-group-4">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="card-offer-style-3">
                      <div class="card-head">
                        <div class="card-image"><img src="assets/images/brand-1.png" alt="" /></div>
                        <div class="carrd-title">
                          <h4 class="color-brand-1">Cross-Platform</h4>
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                        </div>
                      </div>
                      
                      <div class="text-info-bottom text-info-bottom-hm">
                          <div class="icon-location">Bangalore</div>
                          <div class="icon-location1">25 Employees</div>
                        </div>
                      <div class="card-info">
                        <p class="font-sm color-grey-500 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div class="box-button-offer box-button-offer-hm"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More<svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide head-bg-brand-2">
                    <div class="card-offer-style-3">
                      <div class="card-head">
                        <div class="card-image"><img src="assets/images/brand-2.png" alt="" /></div>
                        <div class="carrd-title">
                          <h4 class="color-brand-1">Business strategy</h4>
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                        </div>
                      </div>
                      
                      <div class="text-info-bottom text-info-bottom-hm">
                          <div class="icon-location">Bangalore</div>
                          <div class="icon-location1">25 Employees</div>
                        </div>
                      <div class="card-info">
                        <p class="font-sm color-grey-500 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div class="box-button-offer box-button-offer-hm"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More<svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide head-bg-2">
                    <div class="card-offer-style-3">
                      <div class="card-head">
                        <div class="card-image"><img src="assets/images/brand-8.png" alt="" /></div>
                        <div class="carrd-title">
                          <h4 class="color-brand-1">Local Marketing</h4>
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                        </div>
                      </div>
                      <div class="text-info-bottom text-info-bottom-hm">
                          <div class="icon-location">Bangalore</div>
                          <div class="icon-location1">25 Employees</div>
                        </div>
                      <div class="card-info">
                        <p class="font-sm color-grey-500 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div class="box-button-offer box-button-offer-hm"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More<svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide head-bg-5">
                    <div class="card-offer-style-3">
                      <div class="card-head">
                        <div class="card-image"><img src="assets/images/brand-7.png" alt="" /></div>
                        <div class="carrd-title">
                          <h4 class="color-brand-1">Social Media</h4>
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                          <img alt="" src="assets/images/star.svg" />
                        </div>
                      </div>
                      <div class="text-info-bottom text-info-bottom-hm">
                          <div class="icon-location">Bangalore</div>
                          <div class="icon-location1">25 Employees</div>
                        </div>
                      <div class="card-info">
                        <p class="font-sm color-grey-500 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                         <div class="box-button-offer box-button-offer-hm"><a class="btn btn-default font-sm-bold pl-0 color-brand-1">Learn More<svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-button-slider-bottom">
                  <div class="swiper-button-prev swiper-button-prev-group-4" tabindex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-0c1fd48916d20ee10">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                    </svg>
                  </div>
                  <div class="swiper-button-next swiper-button-next-group-4" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-0c1fd48916d20ee10">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </> 
  ); 
  } 
  export default CompanyMonth;