
import Footer from '../../components/Footer';
import Header from '../../components/Header';    
import Awards from './Awards';
import Banner from './Banner'; 
import CompanyMonth from './CompanyMonth';
import CompanySearch from './CompanySearch';
import FeaturedCompany from './FeaturedCompany';
import HappyClients from './HappyClients';
import Industries from './Industries';
import Subscribe from './Subscribe';
import Testimonial from './Testimonial';
import Trusted from './Trusted';
function Home() { 
	return( 
	<> 

    <Header /> 
    <main class="main"> 
    <Banner/> 
    <Trusted/>
    <CompanySearch/>
    <CompanyMonth/>
    <Industries/>
    <Testimonial/>
    <HappyClients/>
    <Subscribe/>
    <Awards/>
    <Footer/>
    </main>
    {/* 
    <FeaturedCompany/>
    <Subscribe/>
    <Footer/> */}
    
        
  </> 
  ); 
  } 
  export default Home;