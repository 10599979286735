
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';

function Feed() { 
	return( 
	<>
     
     <Header />
      <main class="inner-page" id="main-container"> 
          <section class=" section-padding-60 section-social-feed">
            <div class="container"> 
                <div class="row">
                    <div class="col-lg-3 col-md-12">
                     <LeftSideBar/>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="news-feed-area"> 
                            <div class="news-feed news-feed-post">
                                <div class="post-header d-flex justify-content-between align-items-center">
                                    <div class="image">
                                        <a href="/Profile">
                                            <img src="../assets/images/brand-7.png" class="rounded-circle" alt="image" />
                                        </a>
                                    </div>
                                    <div class="info">
                                        <span class="name"><a href="/Profile">Car Toys Pvt</a></span>
                                        <span class="small-text"><i class="material-icons-outlined">public</i>Public<a href="#">2 mins ago</a></span>
                                    </div>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather-more-horizontal"></i></button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-edit"></i> Edit Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-private"></i> Hide Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-trash"></i> Delete Post</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="post-body">
                                    <div class="post-image">
                                        <div class=" hover-up card-grid-2-1 card-grid-2-2"> 
                                            <div class="card-block-info card-block-feed card-block-feed-1">
                                                <h6>
                                                    <a href="/job-details">UI / UX Designer</a>                                                    
                                                    <span class="location-small">Bagaluru, Karnataka</span>
                                                </h6>
                                                <div>
                                                    <span class="card-briefcase">Fulltime</span>
                                                    <span class="card-time">4 <span> minutes ago</span>
                                                    </span>
                                                </div>
                                                <p class="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>
                                                <div class="mt-10 mb-10">
                                                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Adobe XD</a>
                                                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Figma</a>
                                                    <a class="btn btn-grey-small mr-5" href="/jobs-grid">Photoshop</a>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <ul class="post-meta-wrap d-flex justify-content-between align-items-center">
                                        <li class="post-react">
                                            <a href="#"><i class="material-icons-outlined">favorite_border</i><span class="number">820 </span></a>                                        
                                        </li>
                                        <li class="post-comment">
                                            <a href="#"><i class="material-icons-outlined">chat_bubble_outline</i><span class="number">470 </span></a>
                                        </li>
                                        <li class="post-share">
                                            <a href="#"><img src="../assets/images/share.png" alt="image" /><span class="number">536 </span></a>
                                        </li>
                                    </ul>
                                    <form class="post-footer">
                                        <div class="footer-image">
                                            <a href="#"><img src="../assets/images/user/user-2.jpg" class="rounded-circle" alt="image" /></a>
                                        </div>
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" placeholder="Write a comment..."></textarea>
                                            <label><a href="#"><i class="feather-send"></i></a></label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="news-feed news-feed-post">
                                <div class="post-header d-flex justify-content-between align-items-center">
                                    <div class="image">
                                        <a href="/Profile"><img src="../assets/images/brand-5.png" class="rounded-circle" alt="image" /></a>
                                    </div>
                                    <div class="info">
                                        <span class="name"><a href="/Profile">Ashford Info Tech</a></span>
                                        <span class="small-text"><i class="material-icons-outlined">public</i>Public<a href="#">10 mins ago</a></span>
                                    </div>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather-more-horizontal"></i></button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-edit"></i> Edit Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-private"></i> Hide Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-trash"></i> Delete Post</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="post-body">
                                    <div class="post-image">
                                        <img src="../assets/images/news-feed-post/post-3.jpg" alt="image" />
                                    </div>
                                    <p>Keep Smiling 🧡</p>
                                    <ul class="post-meta-wrap d-flex justify-content-between align-items-center">
                                        <li class="post-react">
                                            <a href="#"><i class="material-icons-outlined">favorite_border</i><span class="number">215 </span></a>
                                        </li>
                                        <li class="post-comment">
                                            <a href="#"><i class="material-icons-outlined">chat_bubble_outline</i><span class="number">340 </span></a>
                                        </li>
                                        <li class="post-share">
                                            <a href="#"><img src="../assets/images/share.png" alt="image" /><span class="number">268 </span></a>
                                        </li>
                                    </ul>
                                    <form class="post-footer">
                                        <div class="footer-image">
                                            <a href="#"><img src="../assets/images/user/user-1.jpg" class="rounded-circle" alt="image" /></a>
                                        </div>
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" placeholder="Write a comment..."></textarea>
                                            <label><a href="#"><i class="feather-send"></i></a></label>
                                        </div>
                                    </form>
                                </div>
                            </div>                            
                            <div class="slider-section">
                                <div class="friend-slide-title">
                                    <h3>Featured Company</h3>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather-more-horizontal"></i></button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-edit"></i> Edit Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-private"></i> Hide Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-trash"></i> Delete Post</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="friend-slide">
                                    <div>
                                        <div class="profile-box friend-box">
                                            <div class="profile-content">
                                                <div class="image-section">
                                                    <div class="profile-img">
                                                        <div>
                                                            <img src="assets/images/brand-2.png" class="img-fluid bg-img" alt="profile"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="profile-detail">
                                                    <h2>Ibotta, Inc</h2>
                                                    <div class="mt-5 feed-star-location">
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <span class="font-xs color-text-mutted ml-10">
                                                        <span>(</span>
                                                        <span>45</span>
                                                        <span>)</span>
                                                        </span>
                                                    </div>
                                                    <span class="card-location">Bengaluru, Karnataka</span>
                                                    <a href="#" class="btn btn-friend-slider">add friend</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="profile-box friend-box">
                                            <div class="profile-content">
                                                <div class="image-section">
                                                    <div class="profile-img">
                                                        <div>
                                                            <img src="assets/images/brand-8.png" class="img-fluid bg-img" alt="profile"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="profile-detail">
                                                    <h2>Brad Snowden</h2>
                                                    <div class="mt-5 feed-star-location">
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <span class="font-xs color-text-mutted ml-10">
                                                        <span>(</span>
                                                        <span>45</span>
                                                        <span>)</span>
                                                        </span>
                                                    </div>
                                                    <span class="card-location">Bengaluru, Karnataka</span>
                                                    <a href="#" class="btn btn-friend-slider">add friend</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="profile-box friend-box">
                                            <div class="profile-content">
                                                <div class="image-section">
                                                    <div class="profile-img">
                                                        <div>
                                                            <img src="assets/images/brand-7.png" class="img-fluid bg-img" alt="profile"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="profile-detail">
                                                    <h2>Mark S. Perry</h2>
                                                    <div class="mt-5 feed-star-location">
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <span class="font-xs color-text-mutted ml-10">
                                                        <span>(</span>
                                                        <span>45</span>
                                                        <span>)</span>
                                                        </span>
                                                    </div>
                                                    <span class="card-location">Bengaluru, Karnataka</span>
                                                    <a href="#" class="btn btn-friend-slider">add friend</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="profile-box friend-box">
                                            <div class="profile-content">
                                                <div class="image-section">
                                                    <div class="profile-img"> 
                                                        <img src="assets/images/brand-6.png" class="img-fluid bg-img" alt="profile"/>
                                                    </div>
                                                </div>
                                                <div class="profile-detail">
                                                    <h2>Exela Movers</h2>
                                                    <div class="mt-5 feed-star-location">
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <img alt="" src="assets/images/star.svg" />
                                                        <span class="font-xs color-text-mutted ml-10">
                                                        <span>(</span>
                                                        <span>45</span>
                                                        <span>)</span>
                                                        </span>
                                                    </div>
                                                    <span class="card-location">Bengaluru, Karnataka</span>
                                                    <a href="#" class="btn btn-friend-slider">add friend</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="news-feed news-feed-post">
                                <div class="post-header d-flex justify-content-between align-items-center">
                                    <div class="image">
                                        <a href="/Profile"><img src="../assets/images/brand-8.png" class="rounded-circle" alt="image" /></a>
                                    </div>
                                    <div class="info">
                                        <span class="name"><a href="/Profile">Baseball Savings</a></span>
                                        <span class="small-text"><i class="material-icons-outlined">public</i>Public<a href="#">12 Mins Ago</a></span>
                                    </div>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather-more-horizontal"></i></button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-edit"></i> Edit Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-private"></i> Hide Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-trash"></i> Delete Post</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="post-body">
                                    <div class="post-image">
                                        <img src="../assets/images/news-feed-post/post-4.jpg" alt="image" />
                                    </div>
                                    <p>Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.</p>
                                    <ul class="post-meta-wrap d-flex justify-content-between align-items-center">
                                        <li class="post-react">
                                            <a href="#"><i class="material-icons-outlined">favorite_border</i><span class="number">149 </span></a>
                                        </li>
                                        <li class="post-comment">
                                            <a href="#"><i class="material-icons-outlined">chat_bubble_outline</i><span class="number">521 </span></a>
                                        </li>
                                        <li class="post-share">
                                            <a href="#"><img src="../assets/images/share.png" alt="image" /><span class="number">134 </span></a>
                                        </li>
                                    </ul>
                                    <div class="post-comment-list">
                                        <div class="comment-list">
                                            <div class="comment-image">
                                                <a href="/Profile"><img src="../assets/images/user/user-15.jpg" class="rounded-circle" alt="image" /></a>
                                            </div>
                                            <div class="comment-info">
                                                <h3>
                                                    <a href="/Profile">Claire P. Toy</a>
                                                </h3>
                                                <span>45 Mins Ago</span>
                                                <p>Donec rutrum congue leo eget malesuada praesent sapien massa convallis a pellentesque nec egestas non nisi curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                                <ul class="comment-react">
                                                    <li><a href="#" class="like">Like(12)</a></li>
                                                    <li><a href="#">Reply</a></li>
                                                </ul>
                                            </div>
                                        </div>                                    
                                        <div class="more-comments">
                                            <a href="#">More Comments+</a>
                                        </div>
                                    </div>
                                    <form class="post-footer">
                                        <div class="footer-image">
                                            <a href="#"><img src="../assets/images/user/user-1.jpg" class="rounded-circle" alt="image" /></a>
                                        </div>
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" placeholder="Write a comment..."></textarea>
                                            <label><a href="#"><i class="feather-send"></i></a></label>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="news-feed news-feed-post">
                                <div class="post-header d-flex justify-content-between align-items-center">
                                    <div class="image">
                                        <a href="/Profile"><img src="../assets/images/brand-4.png" class="rounded-circle" alt="image" /></a>
                                    </div>
                                    <div class="info">
                                        <span class="name"><a href="/Profile">Carols Daughter</a></span>
                                        <span class="small-text"><i class="material-icons-outlined">public</i>Public<a href="#">15 mins ago</a></span>
                                    </div>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather-more-horizontal"></i></button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-edit"></i> Edit Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-private"></i> Hide Post</a></li>
                                            <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="flaticon-trash"></i> Delete Post</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="post-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <div class="post-image">
                                        <img src="../assets/images/news-feed-post/post-5.jpg" alt="image" />
                                    </div>
                                    <ul class="post-meta-wrap d-flex justify-content-between align-items-center">
                                        <li class="post-react">
                                            <a href="#"><i class="material-icons-outlined">favorite_border</i><span class="number">159 </span></a>
                                        </li>
                                        <li class="post-comment">
                                            <a href="#"><i class="material-icons-outlined">chat_bubble_outline</i><span class="number">114 </span></a>
                                        </li>
                                        <li class="post-share">
                                            <a href="#"><img src="../assets/images/share.png" alt="image" /><span class="number">345 </span></a>
                                        </li>
                                    </ul>
                                    <form class="post-footer">
                                        <div class="footer-image">
                                            <a href="#"><img src="../assets/images/user/user-1.jpg" class="rounded-circle" alt="image" /></a>
                                        </div>
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" placeholder="Write a comment..."></textarea>
                                            <label><a href="#"><i class="feather-send"></i></a></label>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="load-more-posts-btn">
                                <a href="#"><i class="flaticon-loading"></i> Load More Posts</a>
                            </div>
                        </div>
                    </div>   
               

                    <div class="col-lg-3 cb-auto-left  cb-dn-none">
                        <RightSideBar/>  
                  </div>
                  
               </div>
            </div>
         </section>
      
                 
      <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default Feed;