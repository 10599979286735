
function Subscribe() { 
	return( 
	<> 



      <section class="section mt-50 bg-grey-80">
        <div class="container">
          <div class="box-newsletter">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-12">
                <div class="box-image-newsletter">
                  <div class="wow animate__animated animate__zoomIn" data-wow-delay=".0s" >
                    <img class="img-main" src="assets/images/newsletter_img.png" alt="" /></div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12"><span class="font-lg color-brand-1 wow animate__animated animate__fadeIn" data-wow-delay=".0s" >Newsletter</span>
                <h2 class="color-brand-1 mb-15 mt-5 wow animate__animated animate__fadeIn" data-wow-delay=".1s" >Subcribe our newsletter</h2>
                <p class="font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".2s" >By clicking the button, you are agreeing with our Term &amp; Conditions</p>
                <div class="form-newsletter mt-30 wow animate__animated animate__fadeIn" data-wow-delay=".3s" >
                  <form action="#">
                    <input type="text" placeholder="Enter you mail .."/>
                    <button class="btn btn-submit-newsletter" type="submit">
                      <svg class="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </> 
  ); 
  } 
  export default Subscribe;