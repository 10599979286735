
function Awards() { 
	return( 
	<>

    <section class="section"> 
        <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center mb-50">
              <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Awards and Recognition</h2>
              <p class="font-lg color-gray-500 wow animate__animated animate__fadeIn" data-wow-delay=".2s">Based on 20+ years of experience, *instinctools solves complex technological challenges with robust, agile solutions.</p>
            </div>
          </div>
            <div class="row justify-content-center">
                <div class="col-lg-12"> 
                    <ul class="list-partners">
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".0s"><img src="assets/images/a1.png" alt="" /></li>
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".1s"><img src="assets/images/a2.png" alt="" /></li>
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".2s"><img src="assets/images/a3.webp" alt="" /></li>
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".3s"><img src="assets/images/a4.png" alt="" /></li>
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".4s"><img src="assets/images/a5.png" alt="" /></li>
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".5s"><img src="assets/images/a6.png" alt="" /></li>
                      <li class="wow animate__animated animate__fadeIn" data-wow-delay=".6s"><img src="assets/images/a7.png" alt="" /></li> 
                    </ul>         
                </div>                
            </div>   
        </div>            
    </section> 
       
  </> 
  ); 
  } 
  export default Awards;