
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import Subscribe from '../Home/Subscribe';

function Company() { 
	return( 
	<>
     
     <Header />
      <main class="inner-page" id="main-container">
      <section class="section banner-contact">
        <div class="container">
          <div class="banner-1">
            <div class="row align-items-center">
              <div class="col-lg-12"><span class="title-line line-48 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Get in Touch</span>
                <h1 class="color-brand-1 mb-20 mt-10 wow animate__animated animate__fadeIn" data-wow-delay=".2s">We’d love to hear from you.</h1>
                <div class="row">
                  <div class="col-lg-9">
                    <p class="font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".4s">Request a demo, ask a question, or get in touch here. If you’re interested in working at Iori Coporation, check out our<a class="ml-3" href="career.html"> careers page.</a></p>
                  </div>
                </div>  
              </div>
              {/* <div class="col-lg-5 d-none d-lg-block">
                <div class="box-banner-contact"><img src="assets/images/banner.png" alt="" /></div>
              </div> */}
            </div>
          </div>
        </div>
      </section>


      <section class="section-box mt-30">
  <div class="container">
    <div class="row flex-row-reverse">
      <div class="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
        <div class="content-page">
          <div class="box-filters-job">
            <div class="row">
              <div class="col-xl-6 col-lg-5">
                <span class="text-small text-showing">Showing <strong>41-60 </strong>of <strong>944 </strong>jobs </span>
              </div>
              <div class="col-xl-6 col-lg-7 text-lg-end mt-sm-15">
                <div class="display-flex2">
                  <div class="box-border mr-10">
                    <span class="text-sortby">Show:</span>
                    <div class="dropdown dropdown-sort">
                      <button class="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                        <span>12</span>
                        <i class="fi-rr-angle-small-down"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                        <li>
                          <a class="dropdown-item active" href="/companies-grid#">10</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/companies-grid#">12</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/companies-grid#">20</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="box-border">
                    <span class="text-sortby">Sort by:</span>
                    <div class="dropdown dropdown-sort">
                      <button class="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                        <span>Newest Post</span>
                        <i class="fi-rr-angle-small-down"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                        <li>
                          <a class="dropdown-item active" href="/companies-grid#">Newest Post</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/companies-grid#">Oldest Post</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/companies-grid#">Rating Post</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="box-view-type">
                    <a class="view-type" href="/jobs-list">
                      <img src="assets/imgs/template/icons/icon-list.svg" alt="" />
                    </a>
                    <a class="view-type" href="/jobs-grid">
                      <img src="assets/imgs/template/icons/icon-grid-hover.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-1.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Car Toys</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>66</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>12</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-2.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Carols Daughter</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>18</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>25</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-3.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Amazon</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>52</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>54</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-4.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Baseball Savings</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>85</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>6</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-5.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Ashford</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>25</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>67</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-6.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Callaway Golf</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>34</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>45</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-7.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Percepta</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>97</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>64</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-8.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Exela Movers</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>67</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>87</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                <div class="image-box">
                  <a href="/company-details">
                    <img src="assets/images/brand-2.png" alt="" />
                  </a>
                </div>
                <div class="info-text mt-10">
                  <h5 class="font-bold">
                    <a href="/company-details">Ibotta, Inc</a>
                  </h5>
                  <div class="mt-5">
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <img alt="" src="assets/images/star.svg" />
                    <span class="font-xs color-text-mutted ml-10">
                      <span>(</span>
                      <span>45</span>
                      <span>)</span>
                    </span>
                  </div>
                  <span class="card-location">Bengaluru, Karnataka</span>
                  <div class="mt-20">
                    <a class="btn btn-grey-big" href="/jobs-grid">
                      <span>23</span>
                      <span>  Available Resources</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <span class="ti-arrow-left"></span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">2</a>
            </li>
            <li class="page-item active">
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">...</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">18</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <span class="ti-arrow-right"></span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12 col-12">
        <div class="sidebar-shadow none-shadow mb-30">
          <div class="sidebar-filters"> 
            <div class="filter-block mb-20">
              <h5 class="medium-heading mb-15">Industry</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">All</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">180</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Software</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">12</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Finance</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">23</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Recruting</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">43</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Management</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">65</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Advertising</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">76</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Popular Keyword</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Software</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">24</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Developer</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">45</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Web</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">57</span>
                  </li>
                </ul>
              </div>
            </div> */}
            <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Position</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Senior</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">12</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Junior</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">35</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Fresher</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">56</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Experience Level</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Internship</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">56</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Entry Level</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">87</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Associate</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">24</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Mid Level</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">45</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Director</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">76</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Executive</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">89</span>
                  </li>
                </ul>
              </div>
            </div> */}
            <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Onsite/Remote</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">On-site</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">12</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Remote</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">65</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Hybrid</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">58</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="filter-block mb-30">
              <h5 class="medium-heading mb-10">Job Posted</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">All</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">78</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">1 day</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">65</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">7 days</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">24</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">30 days</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">56</span>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div class="filter-block mb-20">
              <h5 class="medium-heading mb-15">Job type</h5>
              <div class="form-group">
                <ul class="list-checkbox">
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Full Time</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">25</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" checked="" />
                      <span class="text-small">Part Time</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">64</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Remote Jobs</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">78</span>
                  </li>
                  <li>
                    <label class="cb-container">
                      <input type="checkbox" />
                      <span class="text-small">Freelancer</span>
                      <span class="checkmark"></span>
                    </label>
                    <span class="number-item">97</span>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      
      
    <Subscribe/>
                 
      <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default Company;