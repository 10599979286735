import React from 'react';

function  Footer () {
  return (
  <>
    
    <footer class="footer">
      <div class="footer-1">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-10 footer-logo"><img src="assets/images/logo-white.svg" alt="" /></div>
              <p class="font-md mb-20 color-grey-400">Pixalive Services is the Best Resources and Project Management Platform in India.</p>
              <h6 class="color-brand-1">Follow Us</h6>
              <div class="mt-15">
                <a class="icon-socials icon-facebook" href="#"></a> 
                <a class="icon-socials icon-twitter" href="#"></a>
                <a class="icon-socials icon-linkedin" href="#"></a>
                <a class="icon-socials icon-youtube" href="#"></a>
              </div>
            </div>
            <div class="col-lg-3 mb-30">
              <h5 class="mb-10 color-brand-1">About Us</h5>
              <ul class="menu-footer">
                <li><a href="#">Mission &amp; Vision</a></li>
                <li><a href="#">Our Team</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Press &amp; Media</a></li>
                <li><a href="#">Advertising</a></li>
                <li><a href="#">Testimonials</a></li>
              </ul>
            </div>
            <div class="col-lg-3 mb-30">
              <h5 class="mb-10 color-brand-1">Ressources</h5>
              <ul class="menu-footer">
                <li><a href="#">Project managemen</a></li>
                <li><a href="#">Solutions</a></li>
                <li><a href="#">Customers</a></li>
                <li><a href="#">News &amp; Events</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Support</a></li>
              </ul>
            </div> 
          </div>
        </div>
      </div>
      <div class="footer-2">
        <div class="container">
          <div class="footer-bottom">
            <div class="row"> 
              <div class="col-lg-12 col-md-12 text-center">
                <span class="font-md">Copyright © 2023 Pixalive Designed by Pixalive.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    

  </>
);
}

export default Footer;