
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import Subscribe from '../Home/Subscribe';

function Clients() { 
	return( 
	<>
     
      <Header />
      <main class="inner-page" id="main-container">
      <section class="section banner-contact">
            <div class="container">
              <div class="banner-1">
                <div class="row align-items-center">
                  <div class="col-lg-12"><span class="title-line line-48 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Our Client</span>
                    <h1 class="color-brand-1 mb-20 mt-10 wow animate__animated animate__fadeIn" data-wow-delay=".2s">Creating Your Dream Team</h1>
                    <div class="row">
                      <div class="col-lg-9">
                        <p class="font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".4s">We understand that your Primary Focus Should be on building your company, and that’s why we take care of the hiring process for you.</p>
                      </div>
                    </div>  
                  </div>
                  {/* <div class="col-lg-5 d-none d-lg-block">
                    <div class="box-banner-contact"><img src="assets/images/banner.png" alt="" /></div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <section class="section pt-90 banner-about">
            <div class="container text-center">
                  <h6 class="color-grey-400 mb-15">Who we are</h6>
                  <h2 class="color-brand-1 mb-15">We offer business and IT solutions to <br class="d-none d-lg-block"/>assist companies in overcoming their challenges. </h2>
                  <p class="font-md color-grey-400 mb-30">Sharing Projects and Resources to the companies based on the requirements. Our comprehensive approach <br class="d-none d-lg-block"/>involves closely understanding the needs and objectives of the business. </p>
              <div class="box-radius-border mt-50">
                <div class="box-list-numbers">
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-11.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">469</span><span>K+</span></h2>
                    <p class="color-brand-1 font-lg">Social followers</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-12.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">25</span><span>k+</span></h2>
                    <p class="color-brand-1 font-lg">Happy Clients</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-13.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">756</span><span>+</span></h2>
                    <p class="color-brand-1 font-lg">Project Done</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-14.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">100</span><span>+</span></h2>
                    <p class="color-brand-1 font-lg">Global branch</p>
                  </div>
                  <div class="item-list-number">
                    <div class="box-image-bg"><img src="assets/images/Isolation_Mode-15.svg" alt="" /></div>
                    <h2 class="color-brand-1"><span class="count">240</span><span>+</span></h2>
                    <p class="color-brand-1 font-lg">Conference</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

      <section class="section mt-50 mb-40 box-testimonial-2">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 text-center mb-70">
              <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeIn" data-wow-delay=".0s">What our Customer Saying</h2>
              <p class="font-lg color-gray-500 wow animate__animated animate__fadeIn" data-wow-delay=".2s">Hear from our clients who have <br class="d-none d-lg-block"/>benefited from the service and solutions.</p>
            </div>
          </div>
          <div class="row align-items-start" data-masonry="{&quot;percentPosition&quot;: true }">
            <div class="col-lg-4 col-md-6 mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".0s">
              <div class="card-testimonial-grid">
                <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                </div>
                <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                  <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
              <div class="card-testimonial-grid">
                <div class="box-author mb-10"><a href="#"><img src="assets/images/author1.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Bessie Cooper</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                </div>
                <p class="font-md color-grey-500">Et velit laborum cum temporibus similique qui nostrum odit hic Quis cupiditate et facilis sunt. Et dolorum nostrum qui culpa ullam ut consequatur voluptas eum modi nobis. Ut voluptatem voluptatum hic praesentium eveniet non quae laboriosam nam architecto consequatur aut obcaecati consequatur sit dolores labore ut officia velit. Eos officia consectetur sit labore voluptatem et quia recusandae</p>
                <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                  <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".2s">
              <div class="card-testimonial-grid">
                <div class="box-author mb-10"><a href="#"><img src="assets/images/author2.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Bessie Cooper</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                </div>
                <p class="font-md color-grey-500">Duis justo nulla, pulvinar at dolor dapibus, finibus cursus massa. Praesent quam diam, faucibus tristique enim in, rhoncus aliquam lorem. Vestibulum vestibulum pellentesque nunc sit amet ullamcorper. Praesent ligula felis</p>
                <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                  <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".3s">
              <div class="card-testimonial-grid">
                <div class="box-author mb-10"><a href="#"><img src="assets/images/author2.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Esther Howard</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                </div>
                <p class="font-md color-grey-500">Vivamus venenatis turpis at elit aliquam, in mattis felis ullamcorper. Donec vel elit at diam suscipit volutpat. Donec rhoncus, magna vitae gravida condimentum, massa mauris fermentum est, vitae euismod leo magna vestibulum nunc</p>
                <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                  <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-30 testimonial-warning wow animate__animated animate__fadeIn" data-wow-delay=".4s">
              <div class="card-testimonial-grid">
                <div class="box-author mb-10"><a href="#"><img src="assets/images/author3.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Albert Flores</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                </div>
                <p class="font-md color-grey-500">Vivamus hendrerit molestie mi, a volutpat ipsum volutpat sit amet. Aenean sed metus pulvinar, efficitur lectus sit amet, consectetur nisl. Vivamus hendrerit moles Maecenas lobortis risus Maecenas lobortis risus</p>
                <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                  <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".5s">
              <div class="card-testimonial-grid">
                <div class="box-author mb-10"><a href="#"><img src="assets/images/author4.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Albert Flores</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                </div>
                <p class="font-md color-grey-500">Vivamus hendrerit molestie mi, a volutpat ipsum volutpat sit amet. Aenean sed metus pulvinar, efficitur lectus sit amet, consectetur nisl. Vivamus hendrerit moles Maecenas lobortis risus Maecenas lobortis risus</p>
                <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                  <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section mt-50 bg-grey-80">
        <div class="container">
          <div class="row align-items-end">
            <div class="col-lg-8 col-md-8">
              <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >What our custommers are saying</h2>
              <p class="font-lg color-gray-500 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >Hear from our users who have saved thousands on their Startup<br class="d-none d-lg-block"/> and SaaS solution spend</p>
            </div>
            {/* <div class="col-lg-4 col-md-4 text-md-end text-start"><a class="btn btn-default font-sm-bold pl-0 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >View All
                <svg class="w-6 h-6 icon-16 ml-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg></a></div> */}
          </div>
          <div class="mt-50 wow animate__animated animate__fadeInUp" data-wow-delay=".0s" >
            <div class="box-swiper">
              <div class="swiper-container swiper-group-3 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                <div class="swiper-wrapper" id="swiper-wrapper-110f6653e197cd035" aria-live="off" ><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="2 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="4 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="5 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide swiper-slide-next" data-swiper-slide-index="2" role="group" aria-label="6 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div>
                <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="7 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="8 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="9 / 9" >
                    <div class="card-testimonial-grid">
                      <div class="box-author mb-10"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                        <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Guy Hawkins</span></a><span class="font-xs color-grey-500 department">Bank of America</span></div>
                      </div>
                      <p class="font-md color-grey-500">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                      <div class="card-bottom-info"><span class="font-xs color-grey-500 date-post">29 November 2022</span>
                        <div class="rating text-end"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                      </div>
                    </div>
                  </div></div>
                <div class="swiper-pagination swiper-pagination-2 swiper-pagination-group-3 swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
              <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
          </div>
        </div>
      </section>
      <section class="section mt-50">
        <div class="container">
          <div class="row mt-50 align-items-center">
            <div class="col-xl-5 col-lg-12 mb-40">
              <h2 class="color-brand-1 mt-10 mb-15 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Have a question?<br class="d-none d-lg-block"/>Our team is here and happy to help you</h2>
              <div class="row">
                <div class="col-lg-10 wow animate__animated animate__fadeIn" data-wow-delay=".2s">
                  <p class="font-md color-grey-500">Our platform facilitates connections between companies and top talent, while also assisting companies in acquiring projects and sharing resources.</p>
                </div>
              </div>
              <div class="mt-50 text-start wow animate__animated animate__fadeIn" data-wow-delay=".4s"><a class="btn btn-brand-1 hover-up" href="#">Contact Us</a></div>
            </div>
            <div class="col-xl-7 col-lg-12">
              <div class="box-video-business box-images-team">
                <div class="item-video wow animate__animated animate__fadeIn" data-wow-delay=".0s"><img src="assets/images/question1.png" alt="" /></div>
                <div class="box-image-right">
                  <div class="wow animate__animated animate__fadeIn" data-wow-delay=".2s"><img class="mb-20" src="assets/images/question2.png" alt="" /></div>
                  <div class="wow animate__animated animate__fadeIn" data-wow-delay=".4s"><img src="assets/images/question3.png" alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
    <Subscribe/>
                 
      <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default Clients;