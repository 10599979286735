
function Industries() { 
	return( 
	<>

    <section class="section"> 
        <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center mb-50">
              <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Services we offer</h2>
              <p class="font-lg color-gray-500 wow animate__animated animate__fadeIn" data-wow-delay=".2s">Based on 20+ years of experience, *instinctools solves complex technological challenges with robust, agile solutions.</p>
            </div>
          </div>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                <div class="elementor-row">
  <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4a20a83 skew-columns__tablet" data-id="4a20a83" data-element_type="column">
    <div class="elementor-column-wrap elementor-element-populated">
      <div class="elementor-widget-wrap">
        <div class="elementor-element elementor-element-9f51a8a elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="9f51a8a" data-element_type="widget" data-widget_type="image.default">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/1.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Software Product Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-6afc74f elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="6afc74f" data-element_type="widget" data-widget_type="image.default">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/2.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">SAAS Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-9c05fdf elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="9c05fdf" data-element_type="widget" data-widget_type="image.default">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
              <a href="#">
                  <img src="../assets/images/services/3.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Product Design</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-72a654d elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="72a654d" data-element_type="widget" data-widget_type="image.default">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
              <a href="#">
                  <img src="../assets/images/services/4.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Maintenance & Support</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-c6d3868 elementor-hidden-tablet elementor-hidden-mobile" data-id="c6d3868" data-element_type="column">
    <div class="elementor-column-wrap elementor-element-populated">
      <div class="elementor-widget-wrap">
        <div class="elementor-element elementor-element-978c736 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="978c736" data-element_type="widget" data-widget_type="image.default" data-index-column="1">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
              <a href="#">
                  <img src="../assets/images/services/5.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Enterprise Software Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-10a7fd5 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="10a7fd5" data-element_type="widget" data-widget_type="image.default" data-index-column="1">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/6.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Legacy Software Modernization</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-ef1fdd5 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="ef1fdd5" data-element_type="widget" data-widget_type="image.default" data-index-column="1">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/7.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Web Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-c5f90ba elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="c5f90ba" data-element_type="widget" data-widget_type="image.default" data-index-column="1">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/8.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Mobile App  Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-d6581ad elementor-hidden-tablet elementor-hidden-mobile" data-id="d6581ad" data-element_type="column">
    <div class="elementor-column-wrap elementor-element-populated">
      <div class="elementor-widget-wrap">
        <div class="elementor-element elementor-element-6bc79f5 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="6bc79f5" data-element_type="widget" data-widget_type="image.default" data-index-column="2">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/9.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Business Intelligence</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-0707383 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="0707383" data-element_type="widget" data-widget_type="image.default" data-index-column="2">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/10.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Data Visualization</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-ddfa599 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="ddfa599" data-element_type="widget" data-widget_type="image.default" data-index-column="2">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/11.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Innovation Consulting</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-82c855c elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="82c855c" data-element_type="widget" data-widget_type="image.default" data-index-column="2">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/12.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">CRM Consulting</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f201c44 elementor-hidden-tablet elementor-hidden-mobile" data-id="f201c44" data-element_type="column">
    <div class="elementor-column-wrap elementor-element-populated">
      <div class="elementor-widget-wrap">
        <div class="elementor-element elementor-element-b87b8a8 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="b87b8a8" data-element_type="widget" data-widget_type="image.default" data-index-column="3">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/13.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Dedicated Teams</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-62ce1f2 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="62ce1f2" data-element_type="widget" data-widget_type="image.default" data-index-column="3">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/14.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Custom Software Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-6080685 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="6080685" data-element_type="widget" data-widget_type="image.default" data-index-column="3">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/15.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">Cloud Computing</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
        <div class="elementor-element elementor-element-eaa3ef5 elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="eaa3ef5" data-element_type="widget" data-widget_type="image.default" data-index-column="3">
          <div class="elementor-widget-container">
            <div class="elementor-image">
              <figure class="wp-caption">
                <a href="#">
                  <img src="../assets/images/services/16.jpg"/>
                  <figcaption class="widget-image-caption wp-caption-text">IoT Development</figcaption>
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
                    
                </div>                
            </div>   
        </div>            
    </section> 
       
  </> 
  ); 
  } 
  export default Industries;