
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function Register() { 
	return( 
	<>
     
      <Header />
      <main class="inner-page" id="main-container">

      <section class="section box-page-register">
        <div class="container">
          <div class="row">        
            <div class="col-lg-12">
              <div class="box-register col-md-6">
                <h2 class="color-brand-1 mb-15 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Create an account</h2>
                <p class="mb-20 font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Create an account today and start using our platform</p>
                
                <div class="row wow animate__animated animate__fadeIn" data-wow-delay=".0s">
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-name" type="text" placeholder="Your name"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-phone" type="text" placeholder="Phone"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-email" type="text" placeholder="Email"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-user" type="text" placeholder="Username"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-password" type="text" placeholder="Password"/>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group mb-25">
                      <input class="form-control icon-password" type="text" placeholder="Re-password"/>
                    </div>
                  </div>  
                  <div class="col-lg-12 mt-15">
                    <div class="form-group mb-25">
                      <label class="cb-container">
                        <input type="checkbox" checked="checked"/><span class="text-small">I have read and agree to the Terms & Conditions and the Privacy Policy of this website.</span><span class="checkmark"></span>
                      </label>
                    </div> 
                  </div>
                </div>
                <div class="row align-items-center mt-30 wow animate__animated animate__fadeIn" data-wow-delay=".0s">
                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                    <div class="form-group">
                      <button class="btn btn-brand-lg btn-full font-md-bold" type="submit">Sign up now</button>
                    </div>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6"><span class="d-inline-block align-middle font-sm color-grey-500">Already have an account?</span><a class="d-inline-block align-middle color-success ml-3" href="login.html"> Sign In</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
                
      <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default Register;