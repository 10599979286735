
function LeftSideBar() { 
	return( 
	<>

<div class="sidemenu-area-1">
   <div class="sidemenu-body-1">
      <div class="sidemenu-nav-1 metisMenu h-100" id="sidemenu-nav" data-simplebar> 
         <aside class="widget-area">
            {/* <div class="widget widget-view-profile">
               <div class="profile-box profile-box-sidebar">
                  <a href="/Profile"><img src="../assets/images/brand-8.png" alt="image" /></a>
                  <div class="text ms-2">
                     <h3><a href="/Profile">Snowden Info Tech</a></h3>
                     <div class="mt-5 feed-star-location-1">
                        <img alt="" src="assets/images/star.svg" />
                        <img alt="" src="assets/images/star.svg" />
                        <img alt="" src="assets/images/star.svg" />
                        <img alt="" src="assets/images/star.svg" />
                        <img alt="" src="assets/images/star.svg" />
                        <span class="font-xs color-text-mutted ml-10">
                        <span>(</span>
                        <span>45</span>
                        <span>)</span>
                        </span>
                     </div>
                  </div>
               </div> 
               <div class="profile-btn">
                  <a href="/Profile" class="default-btn">View Profile</a>
               </div>
            </div> */}
            <div class="widget widget-who-following">
               <div class="widget-heading">
                  <h3 class="widget-title">Featured Company</h3>
                  <span><a href="/SuggestionPeople">See All</a></span>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-2.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Carols Daughter</a></span> 
                  <div class="add-friend"><a href="#">Profile</a></div>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-3.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Amazon</a></span>
                  <div class="add-friend"><a href="#">Profile</a></div>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-8.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Baseball Savings</a></span>
                  <div class="add-friend"><a href="#">Profile</a></div>
               </div>
               <div class="following-item d-flex justify-content-between align-items-center">
                  <a href="#"><img src="../assets/images/brand-5.png" class="rounded-circle" alt="image" /></a>
                  <span class="name"><a href="#">Ashford</a></span>
                  <div class="add-friend"><a href="#">Profile</a></div>
               </div>
            </div>

            <div class="widget widget-who-following col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
              <div class="widget-heading">
                  <h3 class="widget-title">Jobs Available</h3>
                  <span><a href="/SuggestionPeople">See All</a></span>
               </div>
              <div class=" hover-up card-grid-2-1">
                <div class="card-grid-2-image-left card-grid-2-image-left-1">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-1.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">LinkedIn</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info card-block-feed">
                  <h6>
                    <a href="/job-details">UI / UX Designer</a>
                  </h6>
                  <div>
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>  
                </div>
              </div>
              <div class=" hover-up card-grid-2-1">
                <div class="card-grid-2-image-left card-grid-2-image-left-1">
                  <span class="flash"></span>
                  <div class="image-box">
                    <img src="assets/images/brand-7.png" alt="" />
                  </div>
                  <div class="right-info">
                    <a class="name-job" href="/company-details">LinkedIn</a>
                    <span class="location-small">Bagaluru, Karnataka</span>
                  </div>
                </div>
                <div class="card-block-info card-block-feed">
                  <h6>
                    <a href="/job-details">Java Software Engineer</a>
                  </h6>
                  <div>
                    <span class="card-briefcase">Fulltime</span>
                    <span class="card-time">4 <span> minutes ago</span>
                    </span>
                  </div>  
                </div>
              </div>
            </div>
            {/* 
            <div class="widget widget-watch-video">
               <div class="widget-heading">
                  <h3 class="widget-title">Trending Video</h3>
                  <span><a href="/TrendingVideo">See All</a></span>
               </div>
               <div class="watch-video-slides owl-carousel owl-theme">
                  <div class="video-item">
                     <img src="../assets/images/watch-video/video-1.jpg" alt="image" />
                     <a href="/TrendingVideo" class="video-btn">
                     <i class="material-icons-round">play_arrow</i>
                     </a>
                  </div>
                  <div class="video-item">
                     <img src="../assets/images/watch-video/video-2.jpg" alt="image" />
                     <a href="/TrendingVideo" class="video-btn">
                     <i class="material-icons-round">play_arrow</i>
                     </a>
                  </div>
                  <div class="video-item">
                     <img src="../assets/images/watch-video/video-3.jpg" alt="image" />
                     <a href="/TrendingVideo" class="video-btn">
                     <i class="material-icons-round">play_arrow</i>
                     </a>
                  </div>
               </div>
            </div>
            */}
            {/* <div class="widget widget-suggested-groups">
               <div class="widget-heading">
                  <h3 class="widget-title">Trending Member</h3>
                  <span><a href="/Category">See All</a></span>
               </div>
               <article class="item">
                  <a href="/SingleCategory" class="thumb">
                  <img src="../assets/images/user/user-2.jpg" class="rounded-circle" alt="image"/>
                  </a>      
                  <div class="info">
                     <div>
                        <h4 class="title">
                           <a href="/SingleCategory">Agriculture</a>
                        </h4>
                        <span>15.6k Followers</span>    
                     </div>
                     <div class="add-friend"><a href="#"><i class="material-icons-outlined">add</i>Profile</a></div>
                  </div>
               </article>
               <article class="item">
                  <a href="#" class="thumb">
                  <img src="../assets/images/user/user-3.jpg" class="rounded-circle" alt="image"/>
                  </a>  
                  <div class="info">
                     <div>
                        <h4 class="title">
                           <a href="/SingleCategory">Beauty</a>
                        </h4>
                        <span>12.7k Followers</span>      
                     </div>
                     <div class="add-friend"><a href="/SingleCategory"><i class="material-icons-outlined">add</i>Profile</a></div>
                  </div>
               </article>
               <article class="item">
                  <a href="/SingleCategory" class="thumb">
                  <img src="../assets/images/user/user-4.jpg" class="rounded-circle" alt="image"/>
                  </a>  
                  <div class="info">
                     <div>
                        <h4 class="title">
                           <a href="/SingleCategory">Photography</a>
                        </h4>
                        <span>10.2k Followers</span>    
                     </div>
                     <div class="add-friend"><a href="#"><i class="material-icons-outlined">add</i>Profile</a></div>
                  </div>
               </article>
            </div> */}
         </aside>
      </div>
   </div>
</div>
       
  </> 
  ); 
  } 
  export default LeftSideBar;