
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import Subscribe from '../Home/Subscribe';

function Talents() { 
	return( 
	<>
     
      <Header />
      <main class="inner-page" id="main-container">

      <section class="section banner-contact">
        <div class="container">
          <div class="banner-1">
            <div class="row align-items-center">
              <div class="col-lg-7"><span class="title-line line-48 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Our Talents</span>
                <h1 class="color-brand-1 mb-20 mt-10 wow animate__animated animate__fadeIn" data-wow-delay=".2s">Together we are strong</h1>
                <div class="row">
                  <div class="col-lg-9">
                    <p class="font-md color-grey-500 wow animate__animated animate__fadeIn" data-wow-delay=".4s">Pool of talents, Dedicated resources to accomplish the projects.</p>
                  </div>
                </div>  
              </div>
              {/* <div class="col-lg-5 d-none d-lg-block">
                <div class="box-banner-contact"><img src="assets/images/banner.png" alt="" /></div>
              </div> */}
            </div>
          </div>
        </div>
      </section>



      <section class="section mt-0">
        <div class="container">
          <h6 class="color-brand-1 mb-20 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Talents</h6>
          <h2 class="color-brand-1 mb-50 wow animate__animated animate__fadeIn" data-wow-delay=".2s">Choose the talents</h2>
          <div class="row">
            
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member4.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Ronald Richards</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 1</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member5.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Kathryn Murphy</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 2</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member6.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Cameron Williamson</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 3</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member7.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Floyd Miles</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 4</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member8.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Devon Lane</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 4</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member9.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Albert Flores</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 5</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member10.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Jenny Wilson</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 7</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member11.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Arlene McCoy</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 8</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member12.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Theresa Webb</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 9</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member1.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Darrell Steward</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 10</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member2.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Guy Hawkins</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 11</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn">
              <div class="card-member">
                <div class="card-top">
                  <div class="card-image"><img src="assets/images/member3.png" alt="" /></div>
                  <div class="card-info"><span class="font-lg-bold color-brand-1">Darlene Robertson</span>
                    <p class="font-xs color-grey-200">Product Designer</p>
                    <div class="list-socials">#Rank - 12</div>
                  </div>
                </div>
                <div class="card-bottom">
                  <p class="font-xs color-grey-500">Joined since 2023, when we were just established. He is a great companion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section bg-grey-80">
        <div class="container">
          <div class="banner-1">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <h2 class="color-brand-1 mb-20 wow animate__animated animate__fadeIn" data-wow-delay=".0s">Hire the resources, to know more about </h2>
                <div class="box-button mt-30 mb-60 wow animate__animated animate__fadeIn" data-wow-delay=".2s">
                  <a class="btn btn-brand-1 hover-up" href="/ContactUs">Contact Us</a>
                </div>
                <p class="font-md color-grey-500 mb-25 wow animate__animated animate__fadeIn" data-wow-delay=".4s">“Highly recommend Iori Agency! They guide us on marketing initiatives and develop great strategies to increase our return on investment. The agency is excellent at being cooperative and responding quickly.”</p>
                <div class="box-author wow animate__animated animate__fadeIn" data-wow-delay=".6s"><a href="#"><img src="assets/images/author.png" alt="" /></a>
                  <div class="author-info"><a href="#"><span class="font-md-bold color-brand-1 author-name">Bessie Cooper</span></a>
                    <div class="rating d-inline-block"><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /><img src="assets/images/star.svg" alt="" /></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 d-none d-lg-block">
                <div class="box-banner-team">
                  <div class="arrow-down-banner shape-1"></div>
                  <div class="arrow-right-banner shape-2"></div>
                  <div class="banner-col-1">
                    <div class="img-banner wow animate__animated animate__zoomIn" data-wow-delay=".0s"><img src="assets/images/banner1.png" alt="" /></div>
                  </div>
                  <div class="banner-col-2">
                    <div class="img-banner wow animate__animated animate__zoomIn" data-wow-delay=".2s"><img src="assets/images/banner2.png" alt="" /></div>
                    <div class="img-banner hasBorder wow animate__animated animate__zoomIn" data-wow-delay=".4s"><img src="assets/images/banner3.png" alt="" /></div>
                  </div>
                  <div class="banner-col-3">
                    <div class="img-banner hasBorder2 wow animate__animated animate__zoomIn" data-wow-delay=".6s"><img src="assets/images/banner4.png" alt="" /></div>
                    <div class="img-banner wow animate__animated animate__zoomIn" data-wow-delay=".8s"><img src="assets/images/banner5.png" alt="" /></div>
                    <div class="img-banner wow animate__animated animate__zoomIn" data-wow-delay="1s"><img src="assets/images/banner6.png" alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section mt-20 mb-40">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h2 class="color-brand-1 mb-0 wow animate__animated animate__fadeInUp" data-wow-delay=".0s">Talent</h2>
              {/* <p class="font-lg color-gray-500 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Updated on September 24, 2023</p> */}
            </div>
          </div>
          <div class="table-box-help mt-50">
            <div class="table-responsive">
              <table class="table table-forum">
                <thead>
                  <tr>
                    <th class="width-50">Company</th>
                    <th class="width-16">Reviews</th> 
                    <th class="width-16">Experince</th>
                    <th class="width-18">People</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-5.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">Announcements</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>4</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-2.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">User Feedback</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>2</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author2.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-4.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">Technology support center</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>5</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author3.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-2.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">Product Support</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>1.5</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author4.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-5.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">Market research</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>4</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author5.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-6.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">Strategic Consulting</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>2</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author6.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item-forum">
                        <div class="item-image"><img src="assets/images/brand-7.png" alt="" /></div>
                        <div class="item-info">
                          <h4 class="color-brand-1">Cognitive Solution</h4>
                          <p class="font-md color-grey-500">Bengaluru, Karnataka</p>
                        </div>
                      </div>
                    </td>
                    <td>3</td>
                    <td>2.5</td>
                    <td>
                      <div class="box-author"><span><img src="assets/images/author7.png" alt="" /></span>
                        <div class="author-info"><span class="font-lg color-brand-1 author-name">Steven Job</span></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      
                
    <Subscribe/>
                  
      <Footer/>
      </main> 

    </> 
    ); 
  } 
  export default Talents;